@media (prefers-reduced-motion: no-preference) {
    #built-with-react {
        animation: React-logo-spin infinite 10s linear;
    }
}

@keyframes React-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}